import { clamp } from "@zap/utils/lib/Math";
import { shallowEqual } from "@zap/utils/lib/Object";
import { IChildren } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { memo, useContext, useLayoutEffect, useRef } from "react";
import { Column, noSpacing } from "./Box";
import { ClickOutside } from "./ClickOutside";
import { HeightContext, duration, roundedBorders, zIndexes } from "./CommonStyles";
import { findOverflowContainer, isPositioned } from "./DomHelpers";
import { AnchorContext, IPopupAnchorContext } from "./PopupAnchor";
import { Portal } from "./Portal";
import { useWindowResize } from "./Resize";
import { Side, Sides } from "./Side";
import { standardSpacing } from "./Sizes";
import { Elevation, shadowStyles } from "./shadow";
import { AnimationDefinition, CSSProperties, Styled, TransformFunctions, animation, ease, elementStyle, important, style } from "./styling";

export { IPopupAnchorContext, PopupAnchor, getAnchorContext, useMouseAnchor } from "./PopupAnchor";

export interface IPopupProps {
    side?: Side;
    align?: Alignment;
    minWidth?: number;
    minHeight?: number;
    show: boolean;
    anchor?: IPopupAnchorContext;
    anchorPosition?: IPopupAnchorContext;
    anchorAlign?: IPopupAnchorContext;
    anchorOffset?: number;
    alignOffset?: number;
    fixed?: boolean;
    allowCover?: boolean;
    noAnimation?: boolean;
    /** Slide along alignment axis instead of positioning axis */
    slideSideways?: boolean;
    elevation?: Elevation;
    square?: boolean;
    transparent?: boolean;
    zIndex?: number;
}

export let Popup = memo(function Popup(props: IPopupProps & IChildren) {
    let contextAnchor = useContext(AnchorContext);
    let contextHeight = useContext(HeightContext);
    let outerRef = useRef<HTMLDivElement>(null);
    let innerRef = useRef<HTMLDivElement>(null);

    let elevation = props.elevation ?? Elevation.Menu;

    useWindowResize(updatePopup);
    useLayoutEffect(updatePopup);

    if (props.show) {
        let popup =
            <Styled.div styles={[popupOuter, props.fixed && fixedPopup, noSpacing]} ref={props.fixed ? undefined : outerRef}>
                <ClickOutside onClick={() => { }} ref={innerRef}>
                    <Column styles={[popupInner, shadowStyles[elevation], !props.square && roundedBorders, !props.transparent && popupBackground]} role="dialog">
                        {props.children}
                    </Column>
                </ClickOutside>
            </Styled.div>;

        return props.fixed
            ? <Portal container={document.body} ref={outerRef}>{popup}</Portal>
            : popup;
    }

    return null;

    function updatePopup() {
        if (props.show) {
            let containerRect = getContainerRect(outerRef.current!);
            updatePopupStyles(containerRect);

            // If popup changes container rect (by causing scrollbars to appear), we need to re-position
            let newContainerRect = getContainerRect(outerRef.current!);
            if (!shallowEqual(containerRect, newContainerRect)) {
                requestAnimationFrame(() => {
                    if (outerRef.current)
                        updatePopupStyles(getContainerRect(outerRef.current));
                });
            }
        }
    }

    function updatePopupStyles(container: Sides<number>) {
        let { outer, inner } = popupStyles(container, outerRef.current!);
        outerRef.current!.style.cssText = elementStyle(outer).toString();
        innerRef.current!.style.cssText = elementStyle(inner).toString();
    }

    function popupStyles(container: Sides<number>, outerElement: HTMLElement): IPopupStyles {
        let popupRect = innerRef.current!.getBoundingClientRect();
        let {
            side = 'bottom' as const,
            align = 'start' as const,
            anchorOffset = 0,
            alignOffset = 0,
            minWidth = popupRect.width,
            minHeight = popupRect.height,
            zIndex = contextHeight.zIndex ?? zIndexes.popup
        } = props;

        let { positionAnchor, alignmentAnchor } = getAnchorRects();
        let offset = getOffsetPosition(outerElement);

        let verticalPositioning = side == 'top' || side == 'bottom';
        let minimum = { width: minWidth, height: minHeight };

        let positionStyles = position(container, positionAnchor, offset, side, anchorOffset, minimum, verticalPositioning ? verticalProps : horizontalProps);
        let alignmentStyles = alignment(container, alignmentAnchor, offset, align, alignOffset, verticalPositioning ? horizontalProps : verticalProps);

        return {
            outer: { ...positionStyles.outer, ...alignmentStyles.outer, zIndex },
            inner: { ...positionStyles.inner, ...alignmentStyles.inner }
        };
    }

    function position(container: Sides<number>, anchor: ClientRect, offset: ClientPosition, side: Side, anchorOffset: number, minimum: Partial<Size>, axis: IAxisProps): IPopupStyles {
        /*
        ┌──────────────container───────────────────┐
        │  ┌─anchor─┐                              │
        │  │        │                              │
                    ←──→                      ←────→
                 anchorOffset            standardSpacing
                       │                      │
                       └─────────grid─────────┘

        no cover:
                         ┌────────────────────┐
                         │<popup> auto        │
                         └────────────────────┘
        allow cover:
        ┌────────────────┬────────────────────┐
        │ space + anchor │<popup> 1fr         │
        └────────────────┴────────────────────┘
        */

        let sideIndex = (side == 'top' || side == 'left') ? 0 : 1;
        let otherSideIndex = (sideIndex + 1) % 2;

        let paddedContainerEnds = [container[axis.start] + standardSpacing, container[axis.end] - standardSpacing];
        let offsetAnchorEnds = [anchor[axis.start] - anchorOffset, anchor[axis.end] + anchorOffset];
        let space = [offsetAnchorEnds[0] - paddedContainerEnds[0], paddedContainerEnds[1] - offsetAnchorEnds[1]];

        if (space[sideIndex] < minimum[axis.length]! && space[otherSideIndex] > space[sideIndex]) {
            side = [axis.start, axis.end][otherSideIndex];
            sideIndex = otherSideIndex;
        }

        let positionTemplates: CSSProperties['gridTemplate'][] = [
            ['1fr', { minmax: [0, space[1] + anchor[axis.length]] }],
            [{ minmax: [0, space[0] + anchor[axis.length]] }, '1fr']
        ];

        let gridEnds = props.allowCover
            ? paddedContainerEnds
            : [
                [paddedContainerEnds[0], offsetAnchorEnds[0]],
                [offsetAnchorEnds[1], paddedContainerEnds[1]],
            ][sideIndex];
        let gridLength = gridEnds[1] - gridEnds[0];

        return {
            outer: {
                position: props.fixed ? 'fixed' : 'absolute',
                [axis.start]: gridEnds[0] - offset[axis.start],
                [axis.length]: gridLength,
                [`gridTemplate${axis.axis}s`]: props.allowCover ? positionTemplates[sideIndex] : 'auto',
                ...(props.noAnimation || props.slideSideways ? {} : { animation: slideAnimations[side] })
            },
            inner: {
                [`grid${axis.axis}`]: props.allowCover ? sideIndex + 1 : 'auto',
                [`${axis.align}Self`]: ['end', 'start'][sideIndex],
                [axis.maxLength]: gridLength
            }
        };
    }

    function alignment(container: Sides<number>, anchor: ClientRect, offset: ClientPosition, align: Alignment, alignOffset: number, axis: IAxisProps): IPopupStyles {
        /*
        ┌──────────────container───────────────────┐
        │  ┌─────────anchor───────────┐            │
        │  │                          │            │
        ←──→                                  ←────→
       padding                         padding=standardSpacing
           ←──→                               │
        alignOffset                           │
              │                               │
              └───────────grid────────────────┘

        start align:
        ┌────────────┬─────────────────────┐
        │beforeAnchor│<popup> 1fr          │
        └────────────┴─────────────────────┘
        end align:
        ┌──────────────────────┬───────────┐
        │           1fr <popup>│afterAnchor│
        └──────────────────────┴───────────┘
        center align:
        ┌────────┬────────────────────────┬┐
        │leftover│ 1fr     <popup>        ││
        └────────┴────────────────────────┴┘
                                          ↑
                    afterAnchor - min(beforeAnchor, afterAnchor) == 0
        */

        let containerEnds = [container[axis.start], container[axis.end]] as const;
        let offsetAnchorEnds = [anchor[axis.start] + alignOffset, anchor[axis.end] - alignOffset] as const;
        let padding = [ // If alignment anchor is already close to the edge, then allow popup to get just as close
            clamp(offsetAnchorEnds[0] - containerEnds[0], 0, standardSpacing),
            clamp(containerEnds[1] - offsetAnchorEnds[1], 0, standardSpacing)
        ];

        let gridEnds = [containerEnds[0] + padding[0], containerEnds[1] - padding[1]];
        let beforeAnchor = offsetAnchorEnds[0] - gridEnds[0];
        let afterAnchor = gridEnds[1] - offsetAnchorEnds[1];
        let alignmentTemplates: Record<Alignment, CSSProperties['gridTemplate']> = {
            start: [{ minmax: [0, beforeAnchor] }, '1fr'],
            end: ['1fr', { minmax: [0, afterAnchor] }],
            center: [
                { minmax: [0, beforeAnchor - Math.min(beforeAnchor, afterAnchor)] },
                '1fr',
                { minmax: [0, afterAnchor - Math.min(beforeAnchor, afterAnchor)] }
            ]
        };
        let gridArea = align == 'end' ? 1 : 2;
        let gridLength = gridEnds[1] - gridEnds[0];

        return {
            outer: {
                [axis.start]: gridEnds[0] - offset[axis.start],
                [axis.length]: gridLength,
                [`gridTemplate${axis.axis}s`]: alignmentTemplates[align],
                ...(props.noAnimation || !props.slideSideways ? {} : { animation: slideAnimations[axis[align == 'end' ? 'start' : 'end']] })
            },
            inner: {
                [`grid${axis.axis}`]: gridArea,
                [`${axis.align}Self`]: align,
                [axis.maxLength]: gridLength
            }
        };
    }

    function getAnchorRects() {
        let positionAnchor = props.anchorPosition?.getRect()
            ?? props.anchor?.getRect()
            ?? contextAnchor.getRect();
        let alignmentAnchor = props.anchorAlign?.getRect()
            ?? positionAnchor;
        return { positionAnchor, alignmentAnchor };
    }

    function getContainerRect(popupElement: HTMLElement) {
        let container = props.fixed ? document.documentElement : findOverflowContainer(popupElement);
        let rect = container.getBoundingClientRect();
        let sides = { top: rect.top, bottom: rect.bottom, left: rect.left, right: rect.right };
        if (Math.floor(container.scrollWidth) > rect.width)
            sides.bottom -= 16; // Horizontal scrollbar
        if (Math.floor(container.scrollHeight) > rect.height)
            sides.right -= 16; // Vertical scrollbar
        return sides;
    }

    function getOffsetPosition(element: HTMLElement): ClientPosition {
        let parent = element.parentElement; // element will be fixed position, so need to look for offsetParent from parent

        if (props.fixed || !parent)
            return { left: 0, top: 0 };

        let offsetParent = isPositioned(getComputedStyle(parent))
            ? parent
            : parent.offsetParent!;
        let rect = offsetParent.getBoundingClientRect();

        return {
            left: rect.left - offsetParent.scrollLeft,
            top: rect.top - offsetParent.scrollTop
        };
    }
});

type End = 'start' | 'end';
export type Alignment = End | 'center';

interface ClientPosition {
    left: number;
    top: number;
}

interface Size {
    width: number;
    height: number;
}

interface IPopupStyles {
    outer: CSSProperties;
    inner: CSSProperties;
}

interface IAxisProps {
    start: 'top' | 'left',
    end: 'bottom' | 'right',
    axis: 'Row' | 'Column',
    align: 'align' | 'justify',
    length: 'width' | 'height',
    maxLength: 'maxWidth' | 'maxHeight'
};

let verticalProps: IAxisProps = {
    start: 'top',
    end: 'bottom',
    axis: 'Row',
    align: 'align',
    length: 'height',
    maxLength: 'maxHeight'
};

let horizontalProps: IAxisProps = {
    start: 'left',
    end: 'right',
    axis: 'Column',
    align: 'justify',
    length: 'width',
    maxLength: 'maxWidth'
};

let popupOuter = style('popup-outer', {
    position: 'fixed', // fixed by default to avoid creating scrollbars
    display: 'grid',
    pointerEvents: 'none',
    margin: important(0)
});

export let fixedPopup = style('popup-fixed', {});

let popupInner = style('popup-inner', {
    pointerEvents: 'all'
});

let popupBackground = style('popup-background', {
    background: 'white'
});

export let slideAnimations: Sides<AnimationDefinition> = {
    top: slide('slideUp', { translateY: standardSpacing }),
    bottom: slide('slideDown', { translateY: -standardSpacing }),
    left: slide('slideLeft', { translateX: standardSpacing }),
    right: slide('slideRight', { translateX: -standardSpacing })
}

function slide(name: string, transform: TransformFunctions) {
    return animation(name, { from: { transform, opacity: 0 } }, duration.appear, ease.enter);
}
