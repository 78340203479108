import { useEffect, useRef, useState } from "react";

const mouseCoords = { x: 0, y: 0 };

type MouseCoords = typeof mouseCoords;

let mousePositionSubscriberCount = 0;

export function useMousePosition(): MouseCoords {
    useEffect(() => {
        mousePositionSubscriberCount++;
        if (mousePositionSubscriberCount == 1)
            window.addEventListener('mousemove', onWindowMouseMove);

        return () => {
            mousePositionSubscriberCount--;
            if (mousePositionSubscriberCount == 0) {
                window.removeEventListener('mousemove', onWindowMouseMove);
                mouseCoords.x = mouseCoords.y = 0;
            }
        }
    }, []);

    return mouseCoords;
}

function onWindowMouseMove(event: MouseEvent) {
    mouseCoords.x = event.pageX;
    mouseCoords.y = event.pageY;
}

export function useWindowMouseMove(onMouseMove: (e: MouseEvent) => void) {
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);
    }, [onMouseMove]);
}

export function useIsMouseDownOnElement<T extends HTMLElement>() {
    let ref = useRef<T>(null);
    let [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        ref.current?.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            ref.current?.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }, []);

    return [ref, isMouseDown] as const;

    function onMouseDown(e: MouseEvent) {
        setIsMouseDown(isMousePressed(e));
    }

    function onMouseUp() {
        setIsMouseDown(false);
    }
}

export function isMousePressed(e: MouseEvent) {
    return e.buttons === 1;
}