import { cloneReactElement, combineRefs, forwardRef } from '@zap/utils/lib/ReactHelpers';
import * as React from 'react';
import { useEffect } from "react";
import { DomRegistrar, IDomRegistrar, useDomScope } from "./DomScope";

export interface IClickOutsideProps {
    onClick(event: MouseEvent): void;
    /** Use with caution, will always fire click-outside events regardless of any blockers */
    ignoreClickBlockers?: boolean;
}

export const ClickOutside = forwardRef(function ClickOutside(props: IClickOutsideProps & { children: React.ReactElement }, ref: React.Ref<any>) {
    let domRegistrar = useClickOutside(props.onClick, props.ignoreClickBlockers);
    let childRef = domRegistrar.useChildRef();
    return <DomRegistrar.Provider value={domRegistrar}>
        {cloneReactElement(props.children, { ref: combineRefs(ref, childRef) })}
    </DomRegistrar.Provider>;
});

/**
 * Given the following scenario:
 * 
 * <document>
 *      <ExplorerButton />
 *      <ExplorerPopup />
 *      <DialogBackground>
 *          <Dialog>
 *              <ComboBox>
 *              </ComboBox>
 *          </Dialog>
 *      </DialogBackground>
 * </document>
 * 
 * useClickOutside should work like this:
 * 
 * |                  |                            Clicked outside                            |
 * | Clicked on       | ExplorerButton | ExplorerPopup | DialogBackground | Dialog | ComboBox |
 * |------------------------------------------------------------------------------------------|
 * | ExplorerButton   |        x       |       x       |         o        |   o    |     o    |
 * | ExplorerPopup    |        x       |       x       |         o        |   o    |     o    |
 * | DialogBackground |        x       |       x       |         x        |   o    |     o    |
 * | Dialog           |        x       |       x       |         x        |   x    |     o    |
 * | ComboBox         |        x       |       x       |         x        |   x    |     x    |
 * 
 * Two main tricky issues:
 * 
 * 1) Clicking on ExplorerButton or ExplorerPopup should not count as clicking outside the other.
 *    The solution is to use a DomScope, and only trigger when clicking outside _all_ the elements in scope.
 * 
 * 2) Clicking on DialogBackground should not trigger the callback for ExplorerButton and ExplorerPopup.
 *    We can't just stop mousedown propagation on the background though, because then we can't detect when
 *    the user has clicked outside the ComboBox, which is inside the DialogBackground.
 *    The solution is to use a capturing handler to make sure we catch every mousedown, and to mark the
 *    DialogBackground element as a "blocker" so we can ignore any useClickOutside usages outside of it.
 */
export function useClickOutside(onClickOutside: (e: MouseEvent) => void, ignoreBlockers = false): IDomRegistrar {
    let domScope = useDomScope();

    useEffect(() => {
        document.addEventListener('mousedown', onMouseDown, true);
        return () => document.removeEventListener('mousedown', onMouseDown, true);
    }, [onClickOutside]);

    return domScope.registrar;

    function onMouseDown(e: MouseEvent) {
        if (clickedOutsideOfEverythingInScope(e) && !clickedOnScrollbar(e))
            onClickOutside(e);
    }

    function clickedOutsideOfEverythingInScope(e: MouseEvent) {
        let blocker = !ignoreBlockers && e.isTrusted
            ? (e.target as HTMLElement).closest(`.${clickOutsideBlocker}`)
            : undefined; // possibly clicked on IFrame
        let unblockedElements = domScope.elements.filter(el => !blocker || blocker.contains(el));
        return !!unblockedElements.length
            && unblockedElements.none(el => el.contains(e.target as HTMLElement));
    }

    function clickedOnScrollbar(event: MouseEvent) {
        let el = event.target as HTMLElement;
        let hasScrollBars = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

        // client height/width do not include scroll bars, so click event with offsets outside this must be inside scroll bar
        return hasScrollBars && (event.offsetY > el.clientHeight || event.offsetX > el.clientWidth);
    }
}

export const clickOutsideBlocker = 'clickOutsideBlocker';