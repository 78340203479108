import { style, Styled } from "@zap/ui/lib/styling";
import { Tooltip as BaseTooltip, ITooltipProps } from "@zap/ui/lib/Tooltip";
import { forwardRef } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { ITooltipDefinition } from "./Desktop/TooltipService";

export type IPortalTooltipProps = {
    definition?: ITooltipDefinition;
    dangerousHtml?: string;
} & ITooltipProps;

export const Tooltip = forwardRef(function Tooltip(props: IPortalTooltipProps, ref: React.Ref<any>) {
    let { definition, dangerousHtml, ...rest } = props;

    if (definition) {
        rest.title = definition.title;
        rest.content = dangerousHtmlTooltip(definition.text!);
        rest.helpUrl = definition.helpUrl;
    } else if (dangerousHtml) {
        rest.content = dangerousHtmlTooltip(dangerousHtml)
    }

    return <BaseTooltip ref={ref} {...rest} />;
});

export function dangerousHtmlTooltip(dangerousHtml: string): React.ReactNode {
    return <Styled.div styles={htmlTooltip} dangerouslySetInnerHTML={{ __html: dangerousHtml }} />;
}

let htmlTooltip = style('htmlTooltip', {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
});