import { forwardRef } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { ComponentProps, Ref } from "react";
import { Styled, style } from "./styling";

export interface IWrapperProps extends Partial<ComponentProps<typeof Styled['div']>> { }

/** 
 * Wrapper element that doesn't affect layout.
 * Useful for getting an element ref or catching events.
 **/
export const Wrapper = forwardRef(function Wrapper({ styles, ...props }: IWrapperProps, ref: Ref<HTMLDivElement>) {
    return <Styled.div ref={ref} styles={[displayContents, styles]} {...props} />
});

let displayContents = style('displayContents', { display: 'contents' });