import { combineHandlers, combineRefs, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { Uuid } from "@zap/utils/lib/Uuid";
import * as React from "react";
import { KeyboardEvent, MouseEvent, ReactNode, Ref, RefObject, memo, useContext, useRef, useState } from "react";
import { RegisteredStyles, StyleCollection } from "stylemap";
import { Row, noSpacing } from "./Box";
import { activeBackgroundOpacity, darkGreyColor, disabledBackground, disabledForeground, duration, errorColor, focusBackgroundOpacity, focusRingOpacity, greyColor, highlightColor, hoverBackgroundOpacity, roundedBorders, whiteColor, zIndexes } from "./CommonStyles";
import { useDisabled } from "./Disabling";
import { FocusHelper, IFocusHelper } from "./FocusHelper";
import { keymap } from "./Keyboard";
import { LinkContext } from "./Link";
import { CSSProperties, Styled, Styles, animation, boxShadow, darken, fade, style, transition } from "./styling";

export type ClickableColor =
    | 'default'
    | 'dangerous'
    | 'grey'
    | 'darkGrey'
    | 'black'
    | 'white';

export type ClickEvent = MouseEvent | KeyboardEvent;

export interface IClickableProps {
    round?: boolean;
    color?: ClickableColor;
    invert?: boolean;
    disabled?: boolean;
    children: ReactNode | ((helper: IFocusHelper) => ReactNode);
    hideRipple?: boolean;
    selected?: boolean;
    styles?: StyleCollection;
    className?: string;
    inline?: CSSProperties;
    tabIndex?: number;
    onClick?: (e: ClickEvent) => void;
    onDoubleClick?: (e: ClickEvent) => void;
    onMouseEnter?: (e: MouseEvent) => void;
    onMouseLeave?: (e: MouseEvent) => void;
    onFocus?: React.FocusEventHandler;
    onBlur?: React.FocusEventHandler;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
    preventFocus?: boolean;
    ignoreKeyboardEvents?: boolean;
    forceHoverStyling?: boolean;
}

export const Clickable = forwardRef(function Clickable(
    {
        round = false,
        color = 'default',
        invert = false,
        disabled = false,
        hideRipple = false,
        selected,
        preventFocus = false,
        ignoreKeyboardEvents = false,
        forceHoverStyling,
        onClick = () => { },
        onDoubleClick = () => { },
        onMouseEnter = () => { },
        onMouseLeave = () => { },
        onFocus,
        onBlur,
        onKeyDown,
        styles, className, inline, tabIndex, children
    }: IClickableProps,
    ref: Ref<HTMLDivElement>) {

    let selectable = typeof selected == 'boolean';

    disabled = useDisabled(disabled);

    let colored = getColorStyles();

    let wrapperRef = useRef<HTMLDivElement>(null);
    let { ripples, addRipple } = useRipples(wrapperRef, colored.color);

    let isInsideLink = useContext(LinkContext);

    return <FocusHelper disabled={disabled}>
        {(helper, focusRef) =>
            <Row inline spacing="half"
                ref={combineRefs(ref, wrapperRef, focusRef)}
                data-testid={testIds.clickable}
                tabIndex={tabIndex}
                className={className}
                styles={[
                    clickableStyle,
                    disabled ? colored.disabled : colored.enabled,
                    round ? circle : square,
                    selected && colored.selected,
                    helper.isFocused && (selectable ? colored.selectableFocused : colored.unselectableFocused),
                    hideRipple && !disabled && colored.hiddenRipple,
                    forceHoverStyling && forceHoverStyle,
                    styles
                ]}
                inlineStyles={inline}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={e => handleMouseDown(e)}
                onKeyDown={combineHandlers(onKeyDown, keymap({ ' ': onActivate, Enter: onActivate }))}
                {...helper.getFocusProps({ onFocus, onBlur })}>
                {typeof children == 'function' ? children(helper) : children}
                {ripples}
            </Row>
        }
    </FocusHelper>;

    function onActivate(e: KeyboardEvent) {
        if (!ignoreKeyboardEvents) {
            e.stopPropagation();
            activate(e);
        }
    }

    function handleClick(e: MouseEvent<HTMLDivElement>) {
        if (!isInsideLink)
            e.stopPropagation();
        if (e.button == 0 && !disabled)
            activate(e);
    }

    function handleDoubleClick(e: MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        onDoubleClick(e);
    }

    function activate(e: ClickEvent) {
        if (!disabled) {
            if (!hideRipple)
                addRipple(rippleOrigin(e));
            onClick(e);
        }
    }

    function handleMouseDown(e: MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        if (preventFocus) e.preventDefault();
    }

    function getColorStyles() {
        return invert
            ? invertedColorStyles[color]
            : colorStyles[color];
    }

    function rippleOrigin(e: ClickEvent): { clientX?: number, clientY?: number } {
        let mouse = e as MouseEvent;
        return ({ clientX: mouse.clientX, clientY: mouse.clientY });
    }
});

let clickableStyle = style('clickable', {
    position: 'relative',
    overflow: 'hidden',
    WebkitTapHighlightColor: 'transparent', // prevent touch flash in chrome
    zIndex: zIndexes.page,
    transition: transition('background', duration.small),
    ':focus': {
        outline: 'none'
    }
});

let forceHoverStyle = style('clickable-forceHover', {});

let colorStyles: Record<ClickableColor, IBackgroundStyles> = {
    default: backgroundStyles('default', highlightColor, false),
    dangerous: backgroundStyles('dangerous', errorColor, false),
    grey: backgroundStyles('grey', greyColor, false),
    darkGrey: backgroundStyles('darkGrey', darkGreyColor, false),
    black: backgroundStyles('black', '#000000', false),
    white: backgroundStyles('white', '#ffffff', false)
};

let invertedColorStyles: Record<ClickableColor, IBackgroundStyles> = {
    default: backgroundStyles('defaultInverted', highlightColor, true),
    dangerous: backgroundStyles('dangerousInverted', errorColor, true),
    grey: backgroundStyles('greyInverted', greyColor, true),
    darkGrey: backgroundStyles('darkGreyInverted', darkGreyColor, true),
    black: backgroundStyles('blackInverted', '#000000', true),
    white: backgroundStyles('whiteInverted', '#ffffff', false)
};

function backgroundStyles(name: string, color: NonNullable<Styles['color']>, invert = false): IBackgroundStyles {
    let foreground = invert ? whiteColor : color;

    let fadeBackground = (opacity: number) => fade(color, invert ? 0.9 - opacity : opacity);

    let enabled = style(`clickable-${name}-enabled`, {
        background: invert ? color : 'transparent',
        color: foreground,
        cursor: 'pointer',
        ':hover': {
            background: fadeBackground(hoverBackgroundOpacity)
        },
        $: {
            [`&.${forceHoverStyle}`]: {
                background: fadeBackground(hoverBackgroundOpacity)
            }
        }
    });

    let selected = style(`clickable-${name}-selected`, {
        background: fadeBackground(focusBackgroundOpacity)
    });

    let disabled = style(`clickable-${name}-disabled`, {
        background: invert ? disabledBackground : 'transparent',
        color: disabledForeground,
        cursor: 'default'
    });

    let unselectableFocused = style(`clickable-${name}-focused`, {
        background: fadeBackground(focusBackgroundOpacity),
        ':hover': {
            background: fadeBackground(focusBackgroundOpacity)
        }
    });

    let selectableFocused = style(`clickable-${name}-selectableFocused`, {
        boxShadow: `inset ${boxShadow(0, 0, 0, 1, invert ? darken(color) : fadeBackground(focusRingOpacity))}`,
        ':hover': {
            background: fadeBackground(focusBackgroundOpacity)
        }
    })

    let hiddenRipple = style(`clickable-${name}-noRipple`, {
        ':active': {
            background: fadeBackground(activeBackgroundOpacity),
            transition: 'none'
        }
    });

    return { color: foreground, selected, unselectableFocused, selectableFocused, enabled, disabled, hiddenRipple };
}

interface IBackgroundStyles {
    color: NonNullable<Styles['color']>;
    selected: RegisteredStyles;
    unselectableFocused: RegisteredStyles;
    selectableFocused: RegisteredStyles;
    enabled: RegisteredStyles;
    disabled: RegisteredStyles;
    hiddenRipple: RegisteredStyles;
}

let square = style('clickable-square', {
    ...roundedBorders
});

let circle = style('clickable-round', {
    borderRadius: '50%'
});

export function useRipples<T extends HTMLElement = HTMLDivElement>(ref: RefObject<T>, color: NonNullable<Styles['color']>, sizeOverride?: number, center?: boolean) {
    let [ripples, setRipples] = useState([] as JSX.Element[]);

    return { ripples, addRipple };

    function addRipple(e: { clientX?: number, clientY?: number }) {
        let key = Uuid.create();
        let ripple = <Ripple key={key} {...getRippleProps(e, key)} />;
        setRipples(rs => rs.concat(ripple));
    }

    function getRippleProps(e: { clientX?: number, clientY?: number }, key: string): IRippleProps {
        let el = ref.current!;
        let rect = el.getBoundingClientRect();
        let size = sizeOverride ?? Math.max(el.clientWidth, el.clientHeight);
        let left = center
            ? 0
            : (e.clientX || rect.left + rect.width / 2) - rect.left - size / 2
        let top = center
            ? 0
            : (e.clientY || rect.top + rect.height / 2) - rect.top - size / 2;

        return {
            left,
            top,
            color,
            size,
            animationDone: () => removeRipple(key)
        }
    }

    function removeRipple(key: string) {
        setRipples(rs => rs.filter(r => r.key !== key));
    }

}

interface IRippleProps {
    left: number,
    top: number,
    size: number,
    color: Styles['color'],
    animationDone(): void
}

const Ripple = memo(function Ripple(props: IRippleProps) {
    return <Styled.div onAnimationEnd={props.animationDone} data-testid={testIds.ripple} styles={[rippleStyle, noSpacing]} inline={customRippleStyles(props)} />;
});

function customRippleStyles({ size, left, top, color }: IRippleProps): CSSProperties {
    return {
        left: left,
        top: top,
        background: color,
        height: size,
        width: size
    };
}

let rippleOpacity = 0.2;

export let rippleStyle = style('ripple', {
    position: 'absolute',
    transition: 'none',
    borderRadius: '100%',
    opacity: 0,
    zIndex: zIndexes.belowPage,
    animation: animation({
        0: {
            opacity: rippleOpacity,
            transform: 'scale(0)'
        },
        50: {
            opacity: 0.7 * rippleOpacity
        },
        100: {
            opacity: 0,
            transform: 'scale(2)'
        }
    }, duration.medium, 'ease-out', 0, 1)
});

export const testIds = {
    clickable: 'clickable',
    ripple: 'ripple'
};

export interface IClickableGroupProps<T> {
    selectedItem?: T;
    items: T[];
    onSelect(item: T): void;
    itemKey(item: T): string;
    children(item: T, helper: IFocusHelper): ReactNode;
    round?: boolean;
}

export function ClickableGroup<T>(props: IClickableGroupProps<T>) {
    return <>
        {props.items.map(item => {
            let itemKey = props.itemKey(item);

            return <Clickable key={itemKey} onClick={() => props.onSelect(item)} selected={isSelected(itemKey)} round={props.round}>
                {helper => props.children(item, helper)}
            </Clickable>
        })}
    </>;

    function isSelected(itemKey: string) {
        return props.selectedItem === undefined
            ? false
            : props.itemKey(props.selectedItem) === itemKey;
    }
}
