import { emptyObject } from "@zap/utils/lib/Object";
import { combineHandlers, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { PropsOfType, StringKey } from "@zap/utils/lib/Types";
import { event, events, model, reduce, reduced } from "event-reduce";
import { reactive, useModel } from "event-reduce-react";
import * as React from "react";
import { KeyboardEventHandler, ReactNode, Ref, createContext, useCallback, useContext, useImperativeHandle, useRef } from "react";
import { ClickEvent } from "./Clickable";
import { DisabledContext } from "./Disabling";
import { FocusDirection, FocusScope, IFocusControl, useFocusControl } from "./FocusScope";

export interface IFormProps<T extends Record<string, any>> extends IFormConfig<T> {
    disabled?: boolean;
    autoFocus?: boolean;
    wrapFocus?: boolean;
    onFocusIn?(event: React.FocusEvent<HTMLElement>, form: IForm<T>): void;
    onFocusOut?(event: React.FocusEvent<HTMLElement>, form: IForm<T>, direction: FocusDirection): void;
    restoreFocus?: boolean;
    children(form: IForm<T>): ReactNode;
}

export interface IFormConfig<T> {
    initial: T;
    /** Default text for all inputs unless overridden. Used for grid editing. */
    initialText?: string;
    onChange?(values: Readonly<T>): void;
    onSubmit?(values: Readonly<T>): void;
    onCancel?(): void;
    onReset?(): void;
    transparentInputs?: boolean;
}

export function Form<T extends Record<string, any>>({ autoFocus, wrapFocus, onFocusIn, onFocusOut, restoreFocus, ...formProps }: IFormProps<T>) {
    let form = useRef<IForm<T>>(null!);
    return <FocusScope
        autoFocus={autoFocus}
        wrap={wrapFocus}
        restoreFocus={restoreFocus}
        onFocusIn={e => onFocusIn?.(e, form.current)}
        onFocusOut={(e, direction) => onFocusOut?.(e, form.current, direction)}
    >
        <FormInner<T> ref={form} {...formProps} />
    </FocusScope>
}

const FormInner = forwardRef(reactive(function FormInner<T extends Record<string, any>>({ disabled, children, ...config }: IFormProps<T>, ref: Ref<IForm<T>>) {
    let focus = useFocusControl();
    let form = useForm(config, focus);
    useImperativeHandle(ref, () => form, [form]);

    return <FormContext.Provider value={form as IForm<any>}>
        <DisabledContext disabled={disabled}>
            {children(form)}
        </DisabledContext>
    </FormContext.Provider>
})) as <T extends Record<string, any>>(props: IFormProps<T> & { ref: Ref<IForm<T>> }) => React.ReactElement;

/** Provides a click handler that calls the appropriate form action based on the type of button. */
export function useFormButtonClick(type: string | undefined, onClick?: (e: ClickEvent) => void) {
    let form = useFormContext();

    return useCallback((e: ClickEvent) => {
        if (type == 'submit')
            form.submit();
        else if (type == 'cancel')
            form.cancel();
        else if (type == 'reset')
            form.reset();

        if (onClick)
            onClick(e);
    }, [form, type, onClick]);
}


export function useForm<T extends Record<string, any>>(config: IFormConfig<T>, focusControl?: IFocusControl) {
    let form = useModel(() => new FormModel(config, focusControl));
    form.setConfig(config);
    return form;
}

export function useFormContext<T extends Record<string, any>>() {
    return useContext(FormContext) as IForm<T>;
}

const FormContext = createContext<IForm<any>>({
    props: (_, props) => props as IFieldProps<any, any>,
    searchProps: (_, props) => props as ISearchFieldProps<any, any>,
    checkboxProps: (_, props) => props as ICheckboxFieldProps<any, any>,
    changeValue: () => { },
    values: emptyObject,
    submit() { },
    cancel() { },
    reset() { }
});

export interface IForm<T extends Record<string, any>> {
    props<Field extends StringKey<T>>(field: Field, props?: Partial<IFieldProps<T, Field>>): IFieldProps<T, Field>;
    searchProps<Field extends StringKey<PropsOfType<T, string>>>(field: Field, props?: Partial<ISearchFieldProps<T, Field>>): ISearchFieldProps<T, Field>;
    checkboxProps<Field extends StringKey<PropsOfType<T, boolean>>>(field: Field, props?: Partial<ICheckboxFieldProps<T, Field>>): ICheckboxFieldProps<T, Field>;
    changeValue<Field extends StringKey<T>>(field: Field, value: T[Field]): void;
    values: Readonly<T>;
    submit(): void;
    cancel(): void;
    reset(): void;
}

type ExtendedFieldProps<
    FieldProps,
    Props extends Partial<FieldProps>
> = FieldProps & Omit<Props, keyof FieldProps>;

@events
class FormEvents<T> {
    valueChanged = event<{ field: keyof T, value: T[keyof T] }>();
    submitted = event();
    cancelled = event();
    reset = event();
}

@model
class FormModel<T extends Record<string, any>> implements IForm<T> {
    private _events = new FormEvents<T>();

    constructor(
        private _config: IFormConfig<T>,
        private _focusControl?: IFocusControl
    ) { }

    setConfig(config: IFormConfig<T>) { this._config = config; }

    props<Field extends StringKey<T>>(
        field: Field,
        { onValueChange, ...rest }: Partial<IFieldProps<T, Field>> = {}
    ) {
        return {
            onValueChange: (value: T[Field]) => {
                onValueChange?.(value);
                this.changeValue(field, value);
            },
            ...this.inputProps(field, rest)
        } satisfies IFieldProps<T, Field>;
    }

    searchProps<Field extends StringKey<PropsOfType<T, string>>>(
        field: Field,
        { onSearch, ...rest }: Partial<ISearchFieldProps<T, Field>> = {}
    ) {
        return {
            onSearch: (text: string) => {
                let result = onSearch?.(text);
                if (result != false)
                    this.changeValue(field, text as any);
                return result;
            },
            ...this.inputProps(field, rest)
        } satisfies ISearchFieldProps<T, Field>;
    }

    private inputProps<Field extends StringKey<T>>(
        field: Field,
        {
            value, // ignored
            onSubmit,
            onCancel,
            forceShowValidation,
            ...rest
        }: Partial<IBaseInputProps<T, Field>> = {}
    ) {
        let fieldValue = this.values[field];
        if (fieldValue === undefined)
            throw new Error(`Missing value for field "${field}".`)
        return {
            name: field,
            value: fieldValue,
            defaultText: this._config.initialText,
            onSubmit: combineHandlers(onSubmit, e => { this.submit(e); }),
            onCancel: combineHandlers(onCancel, () => { this.cancel(); }),
            forceShowValidation: forceShowValidation ?? this.submitted,
            transparent: this._config.transparentInputs,
            ...rest
        } satisfies IBaseInputProps<T, Field>;
    }

    checkboxProps<Field extends StringKey<PropsOfType<T, boolean>>, Props extends Partial<ICheckboxFieldProps<T, Field>>>(
        field: Field,
        {
            name,
            checked,
            onChange,
            ...rest
        }: Props = {} as Props
    ): ExtendedFieldProps<ICheckboxFieldProps<T, Field>, Props> {
        return {
            name: name ?? field,
            checked: this.values[field],
            onChange: combineHandlers(onChange, e => {
                this.changeValue(field, e.target.checked as any);
            }),
            ...rest
        }
    }

    changeValue<Field extends StringKey<T>>(field: Field, value: T[Field]) {
        this._events.valueChanged({ field, value });
        this._config.onChange?.(this.values);
    }

    submit(event?: React.KeyboardEvent<HTMLElement>) {
        if (event && (event.currentTarget as HTMLInputElement).type != 'submit' && this._focusControl?.focusNext()) {
            event.preventDefault(); // Don't want to submit the next input on keyup
        } else {
            this._config.onSubmit?.(this.values);
            this._events.submitted();
        }
    }

    cancel() {
        this._config.onCancel?.();
        this._events.cancelled();
    }

    reset() {
        this._config.onReset?.();
        this._events.reset();
    }

    @reduced
    private submitted = reduce(false, this._events)
        .on(e => e.submitted, () => true)
        .on(e => e.cancelled, () => false)
        .on(e => e.reset, () => false)
        .value;

    @reduced
    values = reduce(this._config.initial, this._events)
        .on(e => e.valueChanged, (values, { field, value }) => Object.freeze(({ ...values, [field]: value })))
        .on(e => e.reset, () => this._config.initial)
        .value;
}

interface IBaseInputProps<T, Field extends keyof T> {
    name: Field;
    value: T[Field];
    defaultText: string | undefined;
    onSubmit: KeyboardEventHandler<HTMLElement>;
    onCancel: KeyboardEventHandler<HTMLElement>;
    forceShowValidation: boolean;
    transparent: boolean | undefined;
}

interface IFieldProps<T, Field extends keyof T> extends IBaseInputProps<T, Field> {
    onValueChange: (value: T[Field]) => void;
}

interface ISearchFieldProps<T, Field extends StringKey<PropsOfType<T, string>>> extends IBaseInputProps<T, Field> {
    onSearch: (value: string) => boolean | void;
}

interface ICheckboxFieldProps<T, Field extends StringKey<PropsOfType<T, boolean>>> {
    name: Field;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}