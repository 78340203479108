let isEnabled = false;
let isOverridden = false;

function enable(enable: boolean) {
    isEnabled = enable;
}

document.addEventListener('keydown', event => {
    if (event.key == 'Control')
        isOverridden = true;
});

document.addEventListener('keyup', event => {
    if (event.key == 'Control')
        isOverridden = false;
});

export const DemonstrationMode = {
    get isEnabled() { return isEnabled && !isOverridden; },
    enable
};