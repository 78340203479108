export function hideSplash(insideOut = false) {
    let splash = document.getElementsByClassName('splash');
    if (document.body.classList.contains('is-splash-quick') || document.body.classList.contains('is-splash-arrowOnly'))
        endSplash();
    else
        splash[0].getElementsByClassName('arrow')[0].addEventListener('animationiteration', endSplash, { once: true });

    function endSplash() {
        if (insideOut)
            splash[0].classList.add('is-splash-insideOut');
        splash[0].classList.add('is-splash-end');
        splash[0].addEventListener('animationend', () => splash[0].remove(), { once: true });
    }
}