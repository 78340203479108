﻿import { applyCommonGlobalStyles } from "@zap/ui/lib/GlobalStyles";
import { enableDevTools } from "event-reduce";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { updateStylesheet } from "stylemap";
import global from "../../../../SharedSource/Web.Core/Client/global";
import { hideSplash } from "@zap/ui/lib/SplashControl";
import { EmbeddedLoginForm } from "./EmbeddedLoginForm";
import { LoginForm } from "./LoginForm";
import { LoginPageModel } from "./LoginPageModel";

let model = new LoginPageModel();
enableDevTools(model);

applyCommonGlobalStyles();
updateStylesheet();

(global as any).boot = (rootElement: HTMLElement, props: ILoginPageProps) => {
    if (isInIFrame()) {
        hideSplash({ insideOut: true });
        ReactDOM.createRoot(rootElement).render(<EmbeddedLoginForm model={model.embedded} {...props} />);
    } else if (props.authSupportsForm) {
        hideSplash({ insideOut: true });
        ReactDOM.createRoot(rootElement).render(<LoginForm model={model.login} {...props} />);
    } else {
        // Don't hide splash - B2C login page has its own animation
        location.href = props.challengeUrl;
    }
}

function isInIFrame() {
    try {
        return window.location.href != window.parent.location.href;
    } catch {
        return true; // Blocked from accessing a cross-origin parent, so must be in an iframe
    }
}

export interface ILoginPageProps {
    authUrl: string;
    challengeUrl: string;
    returnUrl: string;
    headerImage: string;
    isCustomLogo: boolean;
    isPopup: boolean;
    authSupportsForm: boolean;
}
