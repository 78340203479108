import { tryParseInt } from "./Number";

/** Represents a date, with no associated time or time zone */
export class LocalDate {
    private readonly _dateTime: Date;

    constructor(year: number, month: number, date: number) {
        this._dateTime = new Date(Date.UTC(year, month - 1, date));
    }

    get year() { return this._dateTime.getUTCFullYear(); }
    get month() { return this._dateTime.getUTCMonth() + 1; }
    get date() { return this._dateTime.getUTCDate(); }

    add({ years = 0, months = 0, days = 0 }: { years?: number; months?: number; days?: number }) {
        return new LocalDate(this.year + years, this.month + months, this.date + days);
    }

    set({ year, month, date }: { year?: number; month?: number; date?: number }) {
        return new LocalDate(year ?? this.year, month ?? this.month, date ?? this.date);
    }

    inUtc(time?: LocalTime) {
        return time
            ? new Date(Date.UTC(this.year, this.month - 1, this.date, time.hours, time.minutes, time.seconds, time.milliseconds))
            : new Date(Date.UTC(this.year, this.month - 1, this.date));
    }

    inUserTime(time?: LocalTime) {
        return time
            ? new Date(this.year, this.month - 1, this.date, time.hours, time.minutes, time.seconds, time.milliseconds)
            : new Date(this.year, this.month - 1, this.date);
    }

    equals(otherDate: LocalDate) {
        return LocalDate.areEqual(this, otherDate);
    }

    toString() {
        return `${pad(this.year, 4)}-${pad(this.month, 2)}-${pad(this.date, 2)}`;
    }

    toJSON() {
        return this.toString();
    }

    valueOf() {
        return this._dateTime.valueOf();
    }

    static areEqual(a: LocalDate | null | undefined, b: LocalDate | null | undefined) {
        return a?.valueOf() == b?.valueOf();
    }

    static fromUtc(dateTime: Date): LocalDate;
    static fromUtc<Not extends null | undefined>(dateTime: Date | Not): LocalDate | Not;
    static fromUtc<Not extends null | undefined>(dateTime: Date | Not) {
        return dateTime instanceof Date
            ? new LocalDate(dateTime.getUTCFullYear(), dateTime.getUTCMonth() + 1, dateTime.getUTCDate())
            : dateTime;
    }

    static fromUserTime(dateTime: Date): LocalDate;
    static fromUserTime<Not extends null | undefined>(dateTime: Date | Not): LocalDate | Not;
    static fromUserTime<Not extends null | undefined>(dateTime: Date | Not) {
        return dateTime instanceof Date
            ? new LocalDate(dateTime.getFullYear(), dateTime.getMonth() + 1, dateTime.getDate())
            : dateTime;
    }
}

/** Represents a time of day, with no associated date or time zone */
export class LocalTime {
    private readonly _dateTime: Date;

    constructor(hours: number = 0, minutes: number = 0, seconds: number = 0, ms: number = 0) {
        this._dateTime = new Date(new Date(0).setUTCHours(hours, minutes, seconds, ms));
    }

    get hours() { return this._dateTime.getUTCHours(); }
    get minutes() { return this._dateTime.getUTCMinutes(); }
    get seconds() { return this._dateTime.getUTCSeconds(); }
    get milliseconds() { return this._dateTime.getUTCMilliseconds(); }

    set({ hours, minutes, seconds, milliseconds }: { hours?: number; minutes?: number; seconds?: number; milliseconds?: number }) {
        return new LocalTime(hours ?? this.hours, minutes ?? this.minutes, seconds ?? this.seconds, milliseconds ?? this.milliseconds);
    }

    floorToStep(stepSeconds: number) {
        return LocalTime.fromUtc(new Date(this.valueOf() - this.valueOf() % (stepSeconds * 1000)));
    }

    equals(otherTime: LocalTime) {
        return LocalTime.areEqual(this, otherTime);
    }

    toString() {
        return `${pad(this.hours, 2)}:${pad(this.minutes, 2)}:${pad(this.seconds, 2)}.${pad(this.milliseconds, 3)}`;
    }

    toJSON() {
        return this.toString();
    }

    valueOf() {
        return this._dateTime.valueOf();
    }

    static zero = new LocalTime(0, 0, 0, 0);

    static areEqual(a: LocalTime | null | undefined, b: LocalTime | null | undefined) {
        return a?.valueOf() == b?.valueOf();
    }

    static fromUtc(dateTime: Date): LocalTime;
    static fromUtc<Not extends null | undefined>(dateTime: Date | Not): LocalTime | Not;
    static fromUtc<Not extends null | undefined>(dateTime: Date | Not) {
        return dateTime instanceof Date
            ? new LocalTime(dateTime.getUTCHours(), dateTime.getUTCMinutes(), dateTime.getUTCSeconds(), dateTime.getUTCMilliseconds())
            : dateTime;
    }

    static fromUserTime(dateTime: Date): LocalTime;
    static fromUserTime<Not extends null | undefined>(dateTime: Date | Not): LocalTime | Not;
    static fromUserTime<Not extends null | undefined>(dateTime: Date | Not) {
        return dateTime instanceof Date
            ? new LocalTime(dateTime.getHours(), dateTime.getMinutes(), dateTime.getSeconds(), dateTime.getMilliseconds())
            : dateTime;
    }

    static parse(value: string): LocalTime | undefined {
        let parts = value.split(':');
        let hours = tryParseInt(parts[0]);
        let minutes = tryParseInt(parts[1]);
        let seconds = parseFloat(parts[2]);
        let ms = (seconds - Math.floor(seconds)) / 100;
        if (Number.isFinite(hours))
            return new LocalTime(hours, minutes, seconds, ms);
    }
}

function pad(value: number, digits: number) {
    return value.toString().padStart(digits, '0');
}