import * as React from 'react';
import { EventHandler, MutableRefObject, Ref, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import { negate } from "./Function";
import { filterObject, filterObjectKeys, valueOf } from "./Object";

export function cloneAndGetRef(element: React.ReactElement, getRef: (ref: Element) => void, props: object = {}) {
    return cloneReactElement(element, {
        ...props, ref: (value: React.ReactInstance) => {
            let ref = value && findDOMNode(value);
            if (isElement(ref))
                getRef(ref);
        }
    });
}

export function cloneReactElement(element: React.ReactElement, props: object) {
    if ((element as any).ref && (props as any).ref)
        throw new Error(`Ref conflict on ${typeof element.type == 'string' ? element.type : (element.type as any).displayName}. Move the inner ref up to the component doing the cloning.`);

    return React.cloneElement(element, props);
}

function isElement(element: Element | Text | null): element is Element {
    return !!element && !!(element as Element).getBoundingClientRect;
}

export function stopPropagation<E extends Event | React.SyntheticEvent<any>>(eventHandler?: (e: E) => void): (e: E) => void {
    return (e: E) => {
        e.stopPropagation();
        eventHandler?.(e);
    };
}

/** 
 * Calls handlers in order.
 * If <c>stopImmediatePopagation<c> is called on the native event, the remaining handlers are not called.
 **/
export function combineHandlers<E extends SyntheticEvent<any>>(...handlers: (EventHandler<E> | false | null | undefined)[]) {
    return function combinedHandler(event: E) {
        let stopped = false;
        let baseStop = event.nativeEvent.stopImmediatePropagation;
        event.nativeEvent.stopImmediatePropagation = function stopImmediatePropagation() {
            stopped = true;
            baseStop.call(this);
        }

        for (let handler of handlers.notFalsy()) {
            handler(event);
            if (stopped)
                break;
        }
    }
}

export function dataProps<T extends object>(props: T) {
    return filterObjectKeys(props, startsWithData);
}

export function nonDataProps<T extends object>(props: T) {
    return filterObjectKeys(props, negate(startsWithData));
}

type DataProp<Prop extends string = string> = `data-${Prop}`;

export function validProps<T extends object>(props: T): object {
    return filterObject(props, key =>
        miscProps.includes(key as string) || startsWithData(key)
    );
}

function startsWithData<Key extends PropertyKey>(key: Key): key is Extract<Key, DataProp> {
    return typeof key == 'string'
        && key.startsWith('data-');
}

const miscProps = [
    'rich-tooltip' // used in BI for tooltips
];

export interface IChildren {
    children: React.ReactNode;
}

export type RefFn<T> = { bivarianceHack(instance: T | null): void }["bivarianceHack"];

export function refFn<T>(ref: MutableRefObject<T | null>): RefFn<T> {
    return val => ref.current = val;
}

/**
 * Provides a combineRefs function that always returns the same ref instance.
 * Only useful to avoid issues caused refs being given nulls while component is still mounted
 * (see https://github.com/facebook/react/issues/4533#issuecomment-126807678).
 * */
export function useRefCombiner<T>() {
    let refs = useRef([] as CombinableRef<T>[]);
    let combinedRef = useCallback((value: RefValue<T>) => combineRefs(...refs.current)(value), []);
    return (...newRefs: CombinableRef<T>[]) => {
        refs.current = newRefs;
        return combinedRef;
    }
}

type CombinableRef<T> = Ref<RefValue<T>> | undefined;
type RefValue<T> = T extends Element ? T | null : T;

export function combineRefs<T>(...refs: (React.Ref<T> | undefined)[]): RefFn<T> {
    return (value: T | null) => refs.forEach(r => setRef(r, value));
}

export function setRef<T>(ref: React.Ref<T> | undefined, value: T) {
    if (isRefObject(ref))
        (ref as React.MutableRefObject<T>).current = value;
    else if (ref)
        ref(value);
}

function isRefObject<T>(ref: React.Ref<T> | undefined): ref is React.RefObject<T> {
    return !!ref && typeof ref == 'object';
}

export interface RefForwardedComponent<P, R> extends React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<R>> {
    /** Use `displayName` instead */
    name: unknown;
    displayName: string;
}

export interface IDisplayName {
    displayName: string;
}

export function forwardRef<R, P = {}>(component: React.ForwardRefRenderFunction<R, P>): RefForwardedComponent<P, R> {
    let forwarded = React.forwardRef(component) as RefForwardedComponent<P, R>;
    if (process.env.NODE_ENV != 'production' && namedFunctionsSupported && !component.name)
        throw new Error("Must use a named function");
    forwarded.displayName = component.name;
    return forwarded;
}

export function useLazyRef<T>(getValue: () => T) {
    let ref = useRef(undefined! as T);
    if (ref.current === undefined)
        ref.current = getValue();
    return ref;
}

export function useSemiControlledState<T>(defaultValue: T | undefined, value: T | undefined, onValueChanged: (value: T) => void = () => { }) {
    let [currentValue, setCurrentValue] = useState<T>(defaultValue === undefined ? value! : defaultValue);

    let initial = useIsInitialRender();

    useEffect(() => {
        if (!initial && value !== undefined) {
            onValueChanged(value);
            setCurrentValue(value);
        }
    }, [valueOf(value)]);

    return [currentValue, setCurrentValue] as const;
}

export function useMaybeControlledState<T>(defaultValue: T | undefined, value: T | undefined): [T, React.Dispatch<React.SetStateAction<T>>] {
    if (value !== undefined)
        return [value, () => { }];

    if (defaultValue !== undefined)
        return useState<T>(defaultValue);

    throw new Error("Must specify either defaultValue or value");
}

/**
 * @returns [value, setValue, isLoading, error]
 */
export function useAsyncState<T>(defaultValue: T) {
    let [value, setValue] = useState(defaultValue);
    let [isLoading, setIsLoading] = useState(false);
    let [error, setError] = useState(undefined as unknown);
    let promise = useRef(Promise.resolve(undefined!) as PromiseLike<T>);

    let setValueAsync = useCallback(async (value: PromiseLike<T>) => {
        try {
            setIsLoading(true);
            promise.current = value;

            let val = await value;

            if (promise.current == value) {
                setError(undefined);
                setValue(val);
            }
        } catch (err) {
            if (promise.current == value)
                setError(err);
        } finally {
            if (promise.current == value)
                setIsLoading(false);
        }
    }, []);

    return [value, setValueAsync, isLoading, error] as const;
}

/** Calls listener only when right clicking on an element or when pressing the context menu key, not when right-click-dragging. */
export function useContextMenuEvent(listener: (event: MouseEvent) => any) {
    let clickInElement = useRef(false);
    let setClickInsideElement = useCallback(() => clickInElement.current = true, []);
    let setClickOutsideElement = useCallback(() => setTimeout(() => clickInElement.current = false, contextMenuClickOutsideTimeout), []); // setTimeout to allow time for the contextmenu event to fire
    let contextMenuListener = useCallback((e: MouseEvent) => {
        if (!e.button || clickInElement.current)
            listener(e);
    }, [listener]);

    let mousedownRef = useEventListener('mousedown', setClickInsideElement);
    useEventListener('mouseup', setClickOutsideElement)(document.body);
    let contextRef = useEventListener('contextmenu', contextMenuListener);

    return combineRefs(mousedownRef, contextRef);
}

const contextMenuClickOutsideTimeout = 1; // Use timeout > 0 to prevent default right click behaviour when releasing button too quickly in Firefox

export function useEventListener<TEvent extends keyof HTMLElementEventMap, TElement extends HTMLElement>(
    type: TEvent,
    listener: (event: HTMLElementEventMap[TEvent], element: TElement) => any,
    options?: boolean | AddEventListenerOptions,
    ref: React.RefObject<TElement> = useRef<TElement>(null) as React.MutableRefObject<TElement>,
) {
    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener(type, handleEvent, options);
            return () => element.removeEventListener(type, handleEvent, options);
        }
    });

    return refFn(ref);

    function handleEvent(e: HTMLElementEventMap[TEvent]) {
        listener(e, ref.current!);
    }
}

export function useIsInitialRender() {
    let isInitialRender = useRef(true);
    useEffect(() => { isInitialRender.current = false; }, []);
    return isInitialRender.current;
}

var namedFunctionsSupported = !!(function test() { }).name; // For IE
